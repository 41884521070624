.home {
  display: flex;
  height: 100vh;
  align-items: center;
}

.homebg {
  background-image: url(https://i.imgur.com/XhQdVo9.jpg);
  background-size: cover;
  background-position: center;
  width: 100%;
}

.Globals {
  padding-bottom: 20px;
  display: flex;
  /* text-align: right; */
  text-align: left;
  justify-content: flex-end;
  margin-right: 20px;
  /* margin-bottom: 20px; */
}

.Globals a {
  text-decoration: none;
  color: white; /* Optional: inherit the color from the parent element */
}

.homeContain {
  display: flex;
  height: 100%;
  align-items: center;
}

.homeleft {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-img {
  width: 490px;
  height: 490px;
  object-fit: contain;
}

/* .fgs {
    width: 80%;
    font-size: 16px;
    margin-top: 20px;
    color: #ddd;
    text-align: center;
  }
  .homerleft img {
    width: 70%;
  } */
.homeright {
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.topicheader {
  position: relative;
  font-size: 60px;
  color: #ddd;
  font-family: "Arima Madurai", cursive;
  margin-bottom: 10px;
}

.right__sub__title {
  color: #ddd;
  margin-top: -20px;
  text-align: center;
  font-weight: 700;
}

.sub_right__para {
  font-size: 16px;
  margin-top: 20px;
  width: 50%;
  color: #ddd;
  text-align: center;
  /* margin-bottom: 40px; */
}

.home-main-title {
  text-transform: uppercase;
  font-family: "Raleway", sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  font-size: 40px;
  color: rgb(23, 103, 108);
}

.home-par,
.home-short-item {
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  /* width: 70%; */
  color: #000;
  font-weight: 300;
  text-align: left;
  margin-top: -10px;
}

.home-short-item {
  font-weight: 200;
  text-align: center;
  margin-top: 5px;
}

.rightcontain {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connect-txt {
  border: none;
  padding: 10px;
  width: 395px;
  margin: 10px 14px;
  background: rgba(165, 164, 164, 0.3);
}

.connect-txtbtn {
  width: 410px;
  color: #f0f8ff;
  background: teal;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 15px;
}

.inputsect {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textInput {
  margin: 20px;
  border-color: #d3af37;
  font-size: 20px;
  width: 200px;
}

video {
  width: 350px;
  margin-bottom: 30px;
}

.OIKNO {
  border: none;
  background-color: #ffc700;
  padding: 10px;
  font-weight: 700;
  color: #fff;
  width: 200px;
  cursor: pointer;
  box-shadow: 0 6px 0 -2px hsl(0deg 0% 98% / 30%);
}

.textarealeft {
  display: flex;
  text-align: left;
}

.textarealeftm {
  display: none;
}
.textarealeft {
  display: flex;
  color: #000;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 1px;
  text-align: Left;
  text-transform: uppercase;
  line-height: 20px;
}
@media screen and (max-width: 1037px) {
  .home {
    flex-direction: column;
    height: auto;
  }

  video {
    width: 80%;
    height: auto;
  }

  .homerleft {
    margin-top: 60px;
  }

  .homerright {
    flex: 1;
    margin-bottom: 40px;
    /* margin-top: -100px; */
  }
}

@media screen and (max-width: 700px) {
  .topicheader {
    font-size: 40px;
  }

  .right__sub__title {
    font-size: 24px;
  }

  .sub_right__para {
    width: 80%;
  }
}

@media screen and (max-width: 520px) {
  .right__sub__title {
    font-size: 18px;
  }

  .topicheader {
    font-size: 35px;
  }

  .homerleft {
    padding: 0 20px;
  }
}

@media screen and (max-width: 450px) {
  .textarealeftm {
    display: flex;
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;
    width: 80%;
    margin: auto;
    margin-top: 5%;
    line-height: 20px;
  }

  .textarealeft {
    display: none;
  }
  .home {
    flex-direction: column;
    height: auto;
    overflow-x: hidden;
  }

  .homebg {
    background-image: none;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 50%;
  }

  .homeleft {
    background-image: url(https://i.imgur.com/XhQdVo9.jpg);
    background-size: cover;
    background-position: center;
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-par {
    color: #000;
    font-family: Poppins, sans-serif;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;
    margin: auto;
    line-height: 20px;
  }

  .home-short-item {
    color: #000;
    font-family: Poppins, sans-serif;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    width: 70%;
    margin: auto;
  }

  .topicheader {
    font-size: 23px;
  }

  .right__sub__title {
    font-size: 14px;
  }

  .sub_right__para {
    font-size: 14px;
  }

  .fgs {
    font-size: 14px;
  }

  .fgs a {
    font-size: 11px;
  }

  .home-main-title {
    text-transform: uppercase;
    font-family: "Raleway", sans-serif;
    font-weight: 800;
    letter-spacing: 1px;
    font-size: 25px;
    color: rgb(23, 103, 108);
    text-align: center;
  }
  .connect-txtbtn {
    width: 350px;
    font-size: 12px;
  }
  .connect-txt {
    width: 330px;
  }
  .Globals a {
    text-decoration: none;
    color: rgb(0, 0, 0); /* Optional: inherit the color from the parent element */
  }
}
